import styles from "./styles.module.scss";
import { Col, Row, Table, notification, Modal, Tooltip } from "antd";
import React, { useState, useEffect, useContext } from "react";
import type { ColumnsType } from "antd/es/table";
import Icon from "~/src/components/shared/Icon";
import Tags from "~/src/components/shared/Tag";
import EditUserDrawer from "./EditUserDrawer";
import useClient from "~/src/server/utils/client";
import { getCookie } from "src/server/utils/commonUtils";
import DataLoader from "~/src/components/shared/DataLoader";
import { AppContext } from "@src/libs/context";
import { getText } from "~/src/libs/resources";

const ManageUsersTab = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [menuData, setMenuData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(true);
  const { customerStatus } = useContext<any>(AppContext);
  const client = useClient({});
  const { setIsSessionExpired } = useContext<any>(AppContext);
  const dataToParse = [
    {
      id: 0,
      contact_name: "",
      email: "",
      is_customer_admin: false,
      permissions: [],
      email_verified: false,
      is_main_customer_user: false,
      permissions_can_be_changed: false,
    },
  ];
  const [usersData, setUsersData] = useState(dataToParse);

  const editUser = (editUser: any) => {
    setMenuData(editUser);
    setOpenMenu(true);
  };
  const isRemovableEditiableUser = (user: any) => {
    const userInfo = usersData?.find((item) => item?.email === user?.email);
    return userInfo?.permissions_can_be_changed;
  };
  const updateUser = async (values: any) => {
    const userId = usersData?.find(
      (item) => item?.email === menuData?.email
    )?.id;
    try {
      await client.basic.put(
        `/api/ERP/customer/users/${userId}`,
        {
          data: values,
        },
        { headers: { sessionid: getCookie("sessionid") } }
      );
      fetchUsersData();
      notification.success({
        message: `Selected user has been successfully updated!`,
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    }
  };
  const deleteUser = async (editUser: any) => {
    const userId = usersData?.find((item) => item?.email === editUser?.email);
    try {
      await client.basic.delete(`/api/ERP/customer/users/${userId?.id}`, {
        headers: { sessionid: getCookie("sessionid") },
      });
      notification.success({
        message: `Selected user has been successfully deleted!`,
        duration: 4,
      });
      fetchUsersData();
    } catch (error: any) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    }
  };
  const { confirm } = Modal;
  const showDeleteConfirm = (editUser: any) => {
    confirm({
      title: "Are you sure to delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteUser(editUser);
      },
      onCancel() {},
    });
  };

  interface DataType {
    Username: Object;
    Permissions: string[];
    Status: string;
    Action: object;
  }
  const data: DataType[] = [];
  const [usersDataAll, setUsersDataAll] = useState(data);
  const fetchUsersData = async () => {
    setIsLoading(true);
    try {
      const data = await client.basic.get("/api/ERP/customer/users", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setUsersData(data?.data);
    } catch (error: any) {
      if (error?.response?.status === 403) {
        if (
          error?.response?.data?.detail ===
          "You do not have permission to perform this action."
        ) {
          notification.error({
            message: `You do not have permission to perform this action.`,
            duration: 4,
          });
        } else setIsSessionExpired(true);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchUsersData();
  }, []);
  useEffect(() => {
    setShowActions(customerStatus?.permissions?.includes("MANAGE_USERS"));
  }, [customerStatus]);
  useEffect(() => {
    usersData?.forEach(function (item, index) {
      const userItem = {
        Username: {
          name: item?.contact_name,
          email: item?.email,
        },
        Permissions: item?.permissions,
        Status: item?.email_verified ? "Approved" : "Pending",
        Action: {
          name: item?.contact_name,
          email: item?.email,
        },
      };
      data.push(userItem);
    });
    setUsersDataAll(data);
  }, [usersData]);

  const resendInviteHandler = async (editUser: any) => {
    const userId = usersData?.find(
      (item) => item?.email === editUser?.email
    )?.id;
    try {
      await client.basic.post(
        `/api/ERP/customer/users/${userId}/resendInvitation`,
        {},
        {
          headers: { sessionid: getCookie("sessionid") },
        }
      );
      notification.success({
        message: `Confirmation email sent successfully`,
        duration: 4,
      });
      fetchUsersData();
    } catch (error: any) {
      if (error.response && error.response.status === 429) {
        notification.error({
          message: 'Too Many Requests',
          description: 'You have made too many requests. Please try again later.',
          duration: 4,
        });
      } else {
        notification.error({
          message: error?.response?.data?.detail || getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      }
    }
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
      render: (record) => (
        <div className={styles.username}>
          <span className={styles.name}>{record?.name}</span>
          <span className={styles.email}>{record?.email}</span>
        </div>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "Permissions",
      key: "Permissions",
      className: "permissions",
      render: (record) => (
        <Row gutter={[0, 4]}>
          {record?.map((item: string, index: number) => {
            return (
              <Col key={index}>
                <Tags theme="light" key={index} text={item} size="sm" />
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (record) => <Tags theme="light" text={record} size="sm" />,
    },
    showActions
      ? {
          title: "Action",
          dataIndex: "Action",
          key: "Action",
          render: (record) => (
            <div className={styles.icons}>
              {isRemovableEditiableUser(record) && (
                <>
                  <Tooltip title="Edit" placement="bottom">
                    <button className="p-0" onClick={() => editUser(record)}>
                      <Icon name="edit" width={15} height={15} />
                    </button>
                  </Tooltip>
                  <Tooltip title="Edit" placement="bottom">
                    <button
                      className="p-0"
                      onClick={() => showDeleteConfirm(record)}
                    >
                      <Icon name="delete" width={15} height={15} />
                    </button>
                  </Tooltip>
                </>
              )}
            </div>
          ),
        }
      : {
          title: "",
          dataIndex: "",
          key: "",
        },
  ];
  return (
    <div className={styles.ManageUsersTab}>
      {showActions && (
        <h2 className="mb-0">{getText()?.Account?.manageUsers}</h2>
      )}
      <Row>
        {isLoading ? <DataLoader className="py-5" /> : null}
        <Table
          scroll={{ x: "max-content" }}
          className="mx-12 pb-12"
          columns={columns}
          dataSource={usersDataAll}
          pagination={false}
        />
      </Row>
      <EditUserDrawer
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        fetchData={usersData?.find((item) => item?.email === menuData?.email)}
        updateUser={updateUser}
        deleteHandler={showDeleteConfirm}
        resendInviteHandler={resendInviteHandler}
      />
    </div>
  );
};
export default ManageUsersTab;
