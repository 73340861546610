import { Col, Radio, RadioChangeEvent, Row, Tooltip, Modal } from "antd";
import dynamic from "next/dynamic";
import Button from "~/src/components/shared/Button";
import Icon from "~/src/components/shared/Icon";
import Tags from "~/src/components/shared/Tag";
import styles from "./styles.module.scss";
import CreditCardType from "../CreditCardType";
// import { usePlaidLink } from "react-plaid-link";
import { getText } from "~/src/libs/resources";
// import usePlaidPublicKey from "~/src/utilities/hooks/usePlaidPublicKey";
import { memo, useContext, useEffect, useState } from "react";
import { AppContext } from "~/src/libs/context";
const DynamicACHDirectDebit = dynamic(
  () => import("~/src/components/shared/ACHDirectDebit")
);
const ACHPayments = ({
  cards,
  addBankAccount,
  deleteBankAccount,
  setCardAsDefault,
  defaultCard,
}: {
  cards: CreditCardType[];
  defaultCard: CreditCardType;
  addBankAccount: Function;
  deleteBankAccount: Function;
  setCardAsDefault: Function;
}) => {
  const { confirm } = Modal;
  const [addCardMode, setAddCardMode] = useState(false);
  const { customerStatus } = useContext<any>(AppContext);
  // const publicKey = usePlaidPublicKey();

  // const { open, ready } = usePlaidLink({
  //   clientName: "ePallet",
  //   env: process.env.PLAID_ENV as string,
  //   publicKey,
  //   product: ["auth", "transactions"],
  //   onSuccess: (public_token: string, metadata: any) => {
  //     addBankAccount(metadata?.account_id, public_token);
  //   },
  // });

  const onChange = (e: RadioChangeEvent) => {
    setCardAsDefault(e.target.value);
  };
  useEffect(() => {
    if (!addCardMode) {
      document.body.style.overflow = "auto";
    }
  }, [addCardMode]);
  const showDeleteConfirm = (cardId: number) => {
    confirm({
      title: "Are you sure to delete this account?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteBankAccount(cardId);
      },
      onCancel() {},
    });
  };

  return (
    <div className={styles.achPayments}>
      <div className={styles.addressWrapper}>
        <Radio.Group
          className={styles.buttonGroup}
          onChange={onChange}
          value={defaultCard?.id}
        >
          <Row gutter={[16, 16]}>
            {cards?.map((card: CreditCardType, index: number) => (
              <Col
                key={index}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 6 }}
              >
                <div className={styles.itemBox}>
                  <div className={styles.titleContainer}>
                    <Icon name="bankIcon" width={26} height={26} />
                    {card?.is_default ? (
                      <Radio value={card?.id}></Radio>
                    ) : (
                      <Tooltip title="Make default" placement="bottom">
                        <Radio value={card?.id}></Radio>
                      </Tooltip>
                    )}
                  </div>
                  <div className="pb-4">
                    <h3 className={styles.b2}>{card?.bank_name}</h3>
                  </div>
                  <div className="pb-4">
                    <h3 className="font-medium-xs mb-0">
                      {getText()?.Account?.cardNumber}
                    </h3>
                    <span className="font-medium-xs">
                      XXXX XXXX XXXX {card?.four_digits}
                    </span>
                  </div>
                  <div className={styles.lastRow}>
                    <div className={styles.icons}>
                      <Tooltip title='Delete' placement="bottom">
                        <button className="p-0">
                          <Icon
                            name="delete"
                            width={15}
                            height={15}
                            onClick={() => showDeleteConfirm(card?.id)}
                          />
                        </button>
                      </Tooltip>
                    </div>
                    {card?.is_default ? (
                      <Tags theme="light" text="Default" size="sm" />
                    ) : (
                      <a
                        className={`m-0 ${styles.makeDefault}`}
                        onClick={() => setCardAsDefault(card?.id)}
                      >
                        <h6 className="font-semibold-xs m-0">
                          {getText()?.Account?.makeDefaultButton}
                        </h6>
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            ))}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
              xxl={{ span: 6 }}
            >
              {customerStatus?.is_access_new_ach && (
                <Button
                  className={styles.addButton}
                  theme="secondary"
                  onClick={() => setAddCardMode(true)}
                >
                  <div className={styles.icons}>
                    <Icon name="add" width={40} height={40} color="#ffffff" />
                  </div>
                  <div className="pt-8">
                    {getText()?.Account?.addAccountButton}
                  </div>
                </Button>
              )}
              {addCardMode && (
                <DynamicACHDirectDebit
                  source="account"
                  onClose={() => setAddCardMode(false)}
                />
              )}
            </Col>
          </Row>
        </Radio.Group>
      </div>
    </div>
  );
};
export default memo(ACHPayments);
