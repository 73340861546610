import Icon from "@components/shared/Icon";
import QuantityInput from "@components/shared/QuantityInput";
import { useEffect, useState } from "react";
import RemoveModal from "@components/shared/RemoveModal";
import styles from "./styles.module.scss";
import { Col, Divider, Row } from "antd";
import Link from "next/link";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import Image from "next/image";
import { getText } from "~/src/libs/resources";
import useCard from "./useCard";

const Card = ({
  item,
  id,
  setLoading,
  closeMenu,
  getMiniCartItems,
  miniCartItems,
  getCartCount,
}: any) => {
  const [qty, setQty] = useState(item?.quantity);
  const [isModalOpen, setModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(item?.quantity);
  const [price, setPrice] = useState(item?.total_delivered_price);
  const [discount, setDiscount] = useState(item?.total_delivered_discount);
  const [cartEvent, setCartEvent] = useState("");

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const { openMixedPallet, changeQty, changeQtyHandler, cartItemQty } = useCard(
    {
      closeMenu,
      item,
      cartEvent,
      price,
      discount,
      id,
      quantity,
      setQty,
      setCartEvent,
      closeModal,
      getMiniCartItems,
      setLoading,
    }
  );
  useEffect(() => {
    setQty(item?.quantity);
    setDiscount(item?.total_delivered_discount);
    setPrice(item?.total_delivered_price);
    setQuantity(item?.quantity);
  }, [miniCartItems]);

  const handlePlusClick = async () => {
    if (item?.pallet_items?.[0]?.product?.sold_in_stack) {
      setQty(qty + 2);
      await cartItemQty(qty + 2);
    } else {
      setQty(qty + 1);
      await cartItemQty(qty + 1);
    }
    setCartEvent("add_to_cart");
  };

  const handleMinusClick = async () => {
    if (item?.pallet_items?.[0]?.product?.sold_in_stack) {
      setQty(qty - 2);
      await cartItemQty(qty - 2);
    } else {
      setQty(qty - 1);
      await cartItemQty(qty - 1);
    }
    setCartEvent("remove_from_cart");
  };

  const addToCartHandler = async () => {
    await changeQty();
    setCartEvent("");
  };

  useEffect(() => {
    if (cartEvent !== "") {
      addToCartHandler();
    }
  }, [cartEvent]);

  return (
    <>
      <div className={`${styles.cardWrapper} pt-9 pe-9 pb-6 ps-7`}>
        <div className={`${styles.card} `}>
          {item?.pallet_type !== "MIXED_PALLET" ? (
            <div className={`${styles.card__info} me-3`}>
              <div>
                <Link
                  href={`/product/${item?.pallet_items?.[0]?.product?.slug}`}
                >
                  <a>
                    <Image
                      unoptimized
                      className={`${styles.card__info__image}`}
                      src={
                        item?.pallet_items?.[0]?.product?.thumbnail ||
                        "https://cdn.bfldr.com/SK3DIHJL/at/rrxr67wrr7qn946t8f9vggbg/box-empty-white.png?auto=webp&format=png"
                      }
                      alt={item?.name}
                      width="75px"
                      height="75px"
                      layout="fixed"
                    />
                  </a>
                </Link>
              </div>
              <div className={`${styles.card__details} ps-4`}>
                <div>
                  <h6
                    className={`font-bold-xs text-primary mb-2 ${styles.card__details__title}`}
                  >
                    <Link
                      href={`/product-list/brands/${item?.pallet_items?.[0]?.product?.brand?.name}`}
                    >
                      <a
                        onClick={() => {
                          closeMenu();
                          changeQty("select_item");
                        }}
                      >
                        {item?.pallet_items?.[0]?.product?.brand?.name}
                      </a>
                    </Link>
                  </h6>
                  <Link
                    href={`/product/${item?.pallet_items?.[0]?.product?.slug}`}
                  >
                    <a
                      onClick={() => {
                        closeMenu();
                        changeQty("select_item");
                      }}
                    >
                      <p className="mb-4 font-medium-sm">{item?.name}</p>
                    </a>
                  </Link>
                </div>
                <div className={styles.card__details__priceSection}>
                  <p className="mb-0">
                    <label
                      className={`font-medium-xs ${styles.card__details__priceSection__label}`}
                    >
                      {getText()?.Cart?.deliveredPrice}
                    </label>
                    <span className="font-bold-xs ps-1">
                      $
                      {usePriceFormat(item?.total_delivered_price) ||
                        usePriceFormat(0)}
                    </span>
                  </p>
                  <Divider type="vertical" className={styles.divider} />
                  <p className="mb-0">
                    <label
                      className={`font-medium-xs ${styles.card__details__priceSection__label}`}
                    >
                      {getText()?.Header?.MiniCart?.totalCases}
                    </label>
                    <span className="font-bold-xs ps-1">
                      {item?.total_case_quantity || 0}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <Row
              justify="space-between"
              align="middle"
              className={`${styles.mixedPalletTitle} ps-10 font-semibold-h5`}
            >
              <Col span={6} xs={5} sm={5} md={0}>
                <Image
                  unoptimized
                  width="90px"
                  height="90px"
                  className={`${styles.mixedPalletImg} pe-8`}
                  src="https://cdn.bfldr.com/SK3DIHJL/at/6mhvvc45zskm99fgx8hhn7/mixedPallet.svg?auto=webp&format=png"
                  alt={item?.name}
                />
              </Col>
              <Col md={24} xs={18} className={styles.infoCol}>
                <div
                  aria-label="product"
                  className={styles.mixedPalletLink}
                  onClick={() => openMixedPallet(item?.id)}
                >
                  {item?.name}
                </div>
              </Col>
            </Row>
          )}
          <div className={styles.actionContainer}>
            <button className={styles.iconWrapper} onClick={openModal}>
              <Icon name="cross" width={20} height={20} />
            </button>
            {item?.is_available ? (
              <QuantityInput
                value={qty}
                onChange={(value: number) => setQty(value)}
                min={
                  item?.pallet_items?.[0]?.product?.sold_in_stack
                    ? 2
                    : item?.pallet_items?.[0]?.min_pallet_quantity
                }
                max={item?.available_pallet_quantity}
                enterKeyHandler={(e: any) => {
                  e?.target?.value == item.quantity
                    ? null
                    : changeQtyHandler(
                        Number(e?.target?.value),
                        item?.pallet_items?.[0]?.product?.sold_in_stack
                          ? 2
                          : item?.pallet_items?.[0]?.min_pallet_quantity,
                        item?.available_pallet_quantity
                      );
                }}
                onMinusClick={handleMinusClick}
                onPlusClick={handlePlusClick}
                isDisabled={
                  item?.pallet_items?.[0]?.product?.sold_in_stack
                    ? qty === 2
                    : qty < item?.pallet_items?.[0]?.min_pallet_quantity + 1
                    ? true
                    : false
                }
              />
            ) : (
              <div className="font-italic text-tertiaryPink font-regular-xs">
                {item?.is_prototype
                  ? getText()?.PDP?.prototypeProduct
                  : getText().PDP.outOfStock}
              </div>
            )}
          </div>
        </div>
        {item?.pallet_type === "MIXED_PALLET" && (
          <div className={`${styles.mixedPalletItemsWrapper} mt-6`}>
            {item?.pallet_items?.map((item: any, index: number) => (
              <Row
                key={item?.product?.name}
                className={`${styles.mixedPalletItems} px-6 py-8`}
              >
                <Col xs={7} sm={5}>
                  <Image
                    unoptimized
                    width="80px"
                    height="80px"
                    src={item?.product?.thumbnail}
                    alt={item?.product?.name}
                  />
                </Col>
                <Col
                  className={`${styles.mixedPalletItemInfo}`}
                  xs={16}
                  sm={18}
                >
                  <Link href={`/product/${item?.product?.slug}`}>
                    <a
                      onClick={() => {
                        closeMenu();
                        changeQty("select_item");
                      }}
                    >
                      <p className="mb-2 font-bold-xs text-primary">
                        {item?.product?.brand?.name}
                      </p>
                      <p className="mb-4 font-medium-sm">
                        {item?.product?.name}
                      </p>
                    </a>
                  </Link>
                  <Row>
                    <Col>
                      <span className="font-medium-xs pe-1">
                        {getText()?.Cart?.Card?.priceCase}
                      </span>
                      <span className="font-bold-xs">
                        ${usePriceFormat(item?.delivered_case_price)}
                      </span>
                    </Col>
                    <Divider
                      type="vertical"
                      style={{
                        margin: "0 8px",
                        height: "auto",
                        top: 0,
                        background: "#898989",
                        opacity: 0.2,
                      }}
                    />
                    <Col>
                      <span className="font-medium-xs pe-1">
                        <span className={`${styles.totalText}`}>
                          {getText()?.Header?.MiniCart?.total}
                        </span>
                        {getText()?.Header?.MiniCart?.cases}
                      </span>
                      <span className="font-bold-xs">
                        {item?.total_case_quantity}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </div>
        )}
      </div>

      {isModalOpen && (
        <RemoveModal
          confirmRemove={async () => {
            closeModal();
            changeQty("remove_Item");
            await cartItemQty(0);
            getCartCount();
          }}
          closeModal={closeModal}
          confirmationText={"Are you sure you want to delete this item?"}
        />
      )}
    </>
  );
};

export default Card;
