import cx from "classnames";
import Link from "next/link";
import styles from "./styles.module.scss";
import Icon from "@components/shared/Icon";
import { useEffect, useState, useContext, Fragment } from "react";
import { useRouter } from "next/router";
import Overlay from "@components/shared/Overlay";
import Card from "./Card";
import CustomButton from "../../../Button";
import { AppContext } from "@src/libs/context";
import { Modal, notification, Skeleton } from "antd";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";
import { usePriceFormat } from "@src/utilities/hooks/usePriceFormat";
import { getText } from "~/src/libs/resources";
import { groupBy } from "lodash";
import DataLoader from "../../../DataLoader";
import useMiniCart from "~/src/utilities/hooks/useMiniCart";
import { Tooltip } from "antd";

const Cart = ({ className }: any) => {
  const router = useRouter();
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [unavailableItems, setUnavailableItems] = useState<any>([]);
  const [minOrderWeightItems, setMinOrderWeightItems] = useState<any>([]);
  const [minOrderQtyItems, setMinOrderQtyItems] = useState<any>([]);
  const [miniCartItems, setMiniCartItems] = useState<any>();
  const [loading, setLoading] = useState(true);
  const {
    customerStatus,
    setOpenMenuSearch,
    isOpenMiniCart,
    setOpenMiniCart,
    cartCount,
    guestUser,
  } = useContext<any>(AppContext);
  let scrollPosition = 0;
  const {
    getCartCount,
    getMiniCartItems,
    moveToSavedForLater,
    viewCartHandler,
  } = useMiniCart({
    setLoading,
    setMiniCartItems,
    setIsCheckoutDisabled,
    setMinOrderWeightItems,
    setMinOrderQtyItems,
    setUnavailableItems,
    miniCartItems,
  });
  useEffect(() => {
    setIsCheckoutDisabled(false);
    if (cartCount > 0) {
      const pallets = miniCartItems?.pallets;
      const unavailableItemsArr: any = [];
      const minOrderWeightArr: any = [];
      const minOrderQtyArr: any = [];
      pallets?.forEach((item: any) => {
        if (!item?.is_available) {
          setIsCheckoutDisabled(true);
          unavailableItemsArr.push(item);
        }
        if (
          item?.is_available &&
          item?.vendor_pallet_weight < item?.min_order_weight
        ) {
          setIsCheckoutDisabled(true);
          minOrderWeightArr.push(item);
        }
        if (
          item?.is_available &&
          item?.vendor_pallet_quantity < item?.min_order_quantity
        ) {
          setIsCheckoutDisabled(true);
          minOrderQtyArr.push(item);
        }
      });
      setUnavailableItems(unavailableItemsArr);
      setMinOrderQtyItems(minOrderQtyArr);
      setMinOrderWeightItems(minOrderWeightArr);
    }
  }, [miniCartItems]);

  useEffect(() => {
    if (cartCount === 0) {
      closeMenu();
    }
  }, [cartCount]);
  const [hasPlaceOrderPermission, setHasPlaceOrderPermission] =
    useState<boolean>();
  const isMobile = useWindowResize() <= 993;
  const vendorsGroup = groupBy(miniCartItems?.pallets, "sold_by_vendor");
  const vendorsKey = Object.keys(vendorsGroup);

  useEffect(() => {
    if (
      !customerStatus?.permissions?.includes("MANAGE_ORDERS") ||
      !customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES") ||
      (customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES") &&
        !customerStatus?.permissions?.includes("MANAGE_SHIPPING_ADDRESSES") &&
        !customerStatus?.has_addresses)
    )
      setHasPlaceOrderPermission(false);
    else setHasPlaceOrderPermission(true);
  }, [customerStatus]);
  const showMenu = () => {
    getMiniCartItems();
    scrollPosition = window.pageYOffset;
    document.body.style.overflow = "hidden";
    document.body.style.width = "100%";
    setOpenMiniCart(true);
    setOpenMenuSearch(false);
  };
  const closeMenu = () => {
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("width");
    window.scrollTo(0, scrollPosition);
    setOpenMiniCart(false);
  };
  const cartHandler = () => {
    closeMenu();
    setShowModal(false);
    router.push(`/cart`);
  };
  useEffect(() => {
    if (isOpenMiniCart && miniCartItems?.pallets?.length > 0) {
      cartCount > 0 && viewCartHandler("view_cart");
    }
  }, [isOpenMiniCart, miniCartItems]);
  const checkoutHandler = () => {
    const isNotValidPrice = miniCartItems.pallets.some((pallet:any) => !pallet.total_delivered_price);
    if(isNotValidPrice){
      notification.error({
        message: getText()?.Cart?.notValidPrice,
        duration: 4,
      });
      return
    }
    closeMenu();
    if (unavailableItems && unavailableItems.length > 0) {
      unavailableItems?.forEach(async (item: any) => {
        await moveToSavedForLater(item?.id);
      });
    }
    router.push(`/checkout`).then(() => {
      viewCartHandler("begin_checkout");
    });
  };

  useEffect(() => {
    if (
      !guestUser &&
      (customerStatus?.is_vendor || customerStatus?.profile_completed)
    )
      getCartCount();
  }, [guestUser, customerStatus]);

  return (
    <div className={styles.cartWrapper}>
      {cartCount > 0 ? (
       <Tooltip title="Cart" placement="bottom">
        <CustomButton
            theme="link"
            className={cx(className, styles.cart)}
            onClick={() => showMenu()}
          >
            <Icon name="cart" width={28} height={28} />
            {cartCount > 0 && (
              <span className={`${styles.quantity} font-bold-xxs`}>
                {cartCount}
              </span>
            )}
        </CustomButton>
       </Tooltip>
      ) : (
        <Tooltip  title="Cart" placement="bottom">
          <button
            className="p-0 bg-transparent"
            style={{ border: 0 }}
          >
            <Link href={"/cart"}>
              <a className={cx(className, styles.cart)} aria-label="Go to cart">
                <Icon name="cart" width={28} height={28} />
                {cartCount > 0 && (
                  <p className={`${styles.quantity} font-bold-xxs`}>
                    {cartCount}
                  </p>
                )}
              </a>
            </Link>
          </button>
        </Tooltip>
      )}

      {isOpenMiniCart &&
        cartCount > 0 &&
        (!isMobile ? (
          <>
            <Overlay closeMenu={closeMenu} bgOverlay={"bgOverlay"} />
            <div className={`${styles.miniCart} bg-white px-12 pb-20 pt-16`}>
              {loading && (
                <div className={`${styles.miniCartLoader} bg-grey`}>
                  <DataLoader />
                </div>
              )}
              <div className={`${styles.miniCart__header} mb-12`}>
                <h2 className="font-bold-h2 text-oxfordBlue mb-0">
                  {" "}
                  {getText().Cart.myCart}
                </h2>
                <CustomButton
                  className={`${styles.closeIcon} p-0`}
                  onClick={closeMenu}
                >
                  <Icon name="cross" width={25} height={25} />
                </CustomButton>
              </div>

              <div className={styles.miniCart__items}>
                {vendorsKey?.map((vendorName, index) => {
                  return (
                    <Fragment key={index}>
                      <h1
                        className={`mb-6 text-black font-bold-${
                          isMobile ? "sm" : "base"
                        } ${styles.itemTitle}`}
                      >
                        {vendorName}
                      </h1>
                      <div className={`me-12 mb-13 ${styles.vendorsItem}`}>
                        {vendorsGroup[vendorName]?.map(
                          (item: any, index: number) => (
                            <Card
                              closeMenu={closeMenu}
                              key={index}
                              id={index}
                              item={item}
                              setLoading={setLoading}
                              getMiniCartItems={getMiniCartItems}
                              miniCartItems={miniCartItems}
                              getCartCount={getCartCount}
                            />
                          )
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              <div className={`${styles.miniCart__footer} bg-white text-black`}>
                <label className="font-medium-base pt-10">
                  <span> {getText().OrderSummary.itemsTotal}:</span>
                  {!loading ? (
                    <span className="font-bold-base">
                      ${usePriceFormat(miniCartItems?.total_delivered_price)}
                    </span>
                  ) : (
                    <Skeleton.Input active={true} />
                  )}
                </label>
                {miniCartItems?.total_redemption_value > 0 && (
                  <label className="font-medium-base pt-10">
                    <span>{miniCartItems?.redemption_name}:</span>
                    {!loading ? (
                      <span className="font-bold-base">
                        {"$" +
                          usePriceFormat(miniCartItems?.total_redemption_value)}
                      </span>
                    ) : (
                      <Skeleton.Input active={true} />
                    )}
                  </label>
                )}
                <label className="font-medium-base pt-10">
                  <span>{getText().OrderSummary.tax}:</span>
                  {!loading ? (
                    <span className="font-bold-base">
                      {"$" + usePriceFormat(miniCartItems?.total_tax_value)}
                    </span>
                  ) : (
                    <Skeleton.Input active={true} />
                  )}
                </label>
                <label
                  className={`${styles.miniCart__footer__order} font-medium-base pt-10 mt-10`}
                >
                  <span>{getText().OrderSummary.orderTotal}:</span>
                  {!loading ? (
                    <span className="font-bold-base">
                      $
                      {usePriceFormat(
                        miniCartItems?.grand_total_delivered_price
                      )}
                    </span>
                  ) : (
                    <Skeleton.Input active={true} />
                  )}
                </label>
                <div
                  className={`${styles.miniCart__footer__checkoutBtnWrapper} w-100`}
                >
                  <CustomButton
                    className={`${styles.miniCart__footer__viewBtn} font-bold-base py-7 mt-6`}
                    theme="link"
                    onClick={cartHandler}
                  >
                    {getText().Cart.viewCart}
                  </CustomButton>
                  {hasPlaceOrderPermission ? (
                    isCheckoutDisabled ? (
                      <CustomButton
                        className={`${styles.miniCart__footer__checkoutBtn} w-100 font-bold-base py-7 mt-12`}
                        theme="primary"
                        onClick={() => setShowModal(true)}
                      >
                        {getText().Cart.proceedToCheckout}
                      </CustomButton>
                    ) : (
                      <CustomButton
                        className={`${styles.miniCart__footer__checkoutBtn} w-100 font-bold-base py-7 mt-12`}
                        theme="primary"
                        onClick={checkoutHandler}
                        disabled={
                          cartCount === 0 || !customerStatus?.email_verified
                        }
                      >
                        {getText().Cart.proceedToCheckout}
                      </CustomButton>
                    )
                  ) : (
                    <span className="text-discountColor">
                      {getText().Cart.DoNotHavePermissionsToPlaceOrders}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          cartHandler()
        ))}
      {showModal && (
        <Modal
          open={showModal}
          title={getText().Cart.ItemsNotReadyToPurchase}
          footer={false}
          className={styles.modal}
          onCancel={() => {
            closeMenu();
            setShowModal(false);
          }}
        >
          <div>
            {unavailableItems?.map((item: any, index: number) => {
              return (
                <label key={index} className="d-block mb-5">
                  <span className="font-bold-base">{item?.name + ": "}</span>
                  {getText().Cart.ItemNoLongerAvailable}
                </label>
              );
            })}
            {minOrderQtyItems?.map((item: any, index: number) => {
              return (
                <label key={index} className="d-block mb-5">
                  <span className="font-bold-base">{item?.name + ": "}</span>
                  {getText().Cart.minOrderQty}
                  <span className="pe-2">
                    {item?.min_order_quantity - item?.vendor_pallet_quantity}
                  </span>
                  {getText().Cart.palletsFromVendor}
                  <Link href={`/search/${item?.sold_by_vendor}`}>
                    <a
                      className="text-primary"
                      onClick={() => {
                        closeMenu();
                        setShowModal(false);
                      }}
                    >
                      <span className="px-2">{getText().Cart.clickHere}</span>
                    </a>
                  </Link>
                  {getText().Cart.addMore}
                </label>
              );
            })}
            {minOrderWeightItems?.map((item: any, index: number) => {
              return (
                <label key={index} className="d-block mb-5">
                  <span className="font-bold-base">{item?.name + ": "}</span>
                  {getText().Cart.minOrderWeight}
                  {Math.round(
                    Number(
                      item?.min_order_weight - item?.vendor_pallet_weight || 0
                    )
                  )}
                  <span className="ps-2">{getText()?.Cart?.lbs}</span>
                  <Link href={`/search/${item?.sold_by_vendor}`}>
                    <a
                      className="text-primary"
                      onClick={() => {
                        closeMenu();
                        setShowModal(false);
                      }}
                    >
                      <span className="px-2">{getText().Cart.clickHere}</span>
                    </a>
                  </Link>
                  {getText().Cart.addMore}
                </label>
              );
            })}
          </div>
          <div className={styles.modalActions}>
            <div>
              <CustomButton
                className={`${styles.miniCart__footer__checkoutBtn} w-100 font-bold-base py-7 mt-12`}
                theme="primary"
                onClick={cartHandler}
              >
                {getText().Cart.viewCart}
              </CustomButton>
            </div>
            {minOrderQtyItems.length === 0 && minOrderWeightItems === 0 && (
              <div>
                <CustomButton
                  className={`${styles.miniCart__footer__checkoutBtn} w-100 font-bold-base py-7 mt-12`}
                  theme="primary"
                  onClick={checkoutHandler}
                  disabled={cartCount === 0 || !customerStatus?.email_verified}
                >
                  {getText().Cart.proceedToCheckout}
                </CustomButton>
                <span className="font-regular-xs">
                  {getText().Cart.saveToCompleteLater}
                </span>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Cart;
