import React, { useState, useEffect, useContext, useRef } from "react";
import { Carousel, Row, Skeleton } from "antd";
import Button from "@components/shared/Button";
import Link from "next/link";
import styles from "./styles.module.scss";
import ProductCard from "@components/shared/ProductCard";
import FeedbackCard from "@components/shared/FeedbackCard";
import ArticleCard from "@components/shared/ArticleCard";
import classnames from "classnames";
import axios from "axios";
import { FeedBackArrProps } from "./types";
import { dataSource } from "./carouselDataSrc";
import { NextArrow, PrevArrow } from "./Arrows";
import DataLoader from "@components/shared/DataLoader";
import { AppContext } from "@src/libs/context";
import { getText } from "~/src/libs/resources";
import CarouselSkeleton from "./Skeleton/Skeleton";
import useCarousel from "~/src/libs/utils/useCarousel";
import { useRouter } from "next/router";

interface CarouselProps {
  type?: string;
  dataSrc?: string;
  viewAll?: boolean;
  title?: string;
  subTitle?: any;
  backgroundColor?: string;
  btnUrl?: string;
  carouselClass?: string;
  productCardClass?: string;
  mainCategory?: string;
  subCategory?: string;
  productSlug?: string;
  AllowedUsers?: string;
  modalShowSet?: any;
  frameOverflow?: string;
  carouselSettings?: {
    slidesToShow: number;
    responsive: any;
  };
  onClickHandler?:any
  id?: number
}

const CustomCarousel = ({
  type = "Products",
  dataSrc,
  viewAll,
  title,
  subTitle,
  backgroundColor,
  btnUrl = "",
  carouselClass,
  productCardClass,
  mainCategory = "",
  subCategory = "",
  productSlug = "",
  AllowedUsers = "Both",
  modalShowSet,
  carouselSettings,
  onClickHandler,
  id
}: CarouselProps) => {
  const [data, setData] = useState([{}, {}, {}, {}, {}, {}, {}, {}]);
  const {
    guestUser,
    vendorID,
    customerStatus,
    defaultPostZip,
    shippingAddresses,
    showNonActiveProduct,
    isVendor,
  } = useContext<any>(AppContext);
  const carouselRef: any = useRef();
  const [productData, setProductData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [feedBackData, setFeedBackData] = useState<Array<FeedBackArrProps>>([]);
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
  let defaultShipping: any;

  shippingAddresses?.forEach((item: any) => {
    if (item["is_default"] === true) {
      defaultShipping = item;
    }
  });

  const {
    fetchMixMatchVolumeProducts,
    fetchViewedItems,
    fetchRecommendedSearches,
    fetchPopularThisMonth,
    fetchRelatedProducts,
    fetchBestSellers,
    fetchCrossPromotedProducts,
    addGoogleAnalytics,
    fetchProductData,
    fetchHaggleRewards,
  } = useCarousel({
    setProductData,
    setIsLoading,
    productData,
    title,
    productSlug,
    mainCategory,
    subCategory,
    modalShowSet,
    id
  });
  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  useEffect(() => {
    const userCanAccess =
      AllowedUsers === "Both" ||
      (AllowedUsers === "Registered" && !isGuestUser) ||
      (AllowedUsers === "Guest" && isGuestUser);
    if (userCanAccess && (type === "Feedbacks" || type === "Articles")) {
      const fetchData = async () => {
        try {
          const { data } = await axios.get("/api/ERP/common/testimonials");
          setFeedBackData(data);
        } catch (e) {}
      };
      fetchData().catch(console.error);
    }
  }, [isGuestUser]);

  const settings = {
    arrows: true,
    dots:
      type === "Feedbacks" || dataSrc === dataSource.RECENTLY_VIEWED
        ? false
        : true,
    speed: 500,
    slidesToShow: carouselSettings?.slidesToShow || 4,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: false,
    centerMode: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots:
            type === "Feedbacks" || dataSrc === dataSource.RECENTLY_VIEWED
              ? false
              : true,
          ...(carouselSettings?.responsive?.["1440"] ?? {}),
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots:
            type === "Feedbacks" || dataSrc === dataSource.RECENTLY_VIEWED
              ? false
              : true,
          ...(carouselSettings?.responsive?.["1150"] ?? {}),
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots:
            type === "Feedbacks" || dataSrc === dataSource.RECENTLY_VIEWED
              ? false
              : true,
          ...(carouselSettings?.responsive?.["1023"] ?? {}),
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          ...(carouselSettings?.responsive?.["768"] ?? {}),
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots:
            dataSrc === dataSource.MIX_MATCH_VOLUME_DISCOUNT ||
            type === "Feedbacks" ||
            dataSrc === dataSource.RECENTLY_VIEWED
              ? false
              : true,
        },
      },
    ],
  };

  const fetchCarouselItems = () =>{
    const isvViewedItems = (dataSrc === dataSource.VIEWED_ITEMS || dataSrc === dataSource.RECENTLY_VIEWED) && customerStatus?.is_authenticated
    const isRecommendedSearch = dataSrc === dataSource.RECOMMENDED_SEARCHES
    const hasAddress = shippingAddresses?.length > 0 || (shippingAddresses?.length < 1 && defaultPostZip?.length > 0)
    if (
      (customerStatus &&
        Object.keys(customerStatus).length !== 0 &&
        hasAddress &&
        type === "Products") || isvViewedItems ||
      (isRecommendedSearch && type === "Products") 
    ) {
      switch (dataSrc) {
        case dataSource.RECENTLY_VIEWED:
          fetchViewedItems();
          break;

        case dataSource.FEATURED_PRODUCT:
          fetchProductData();
          break;

        case dataSource.YOU_MIGHT_LIKE:
          fetchCrossPromotedProducts();
          break;
        case dataSource.VIEWED_ITEMS:
          fetchViewedItems();
          break;

        case dataSource.BEST_SELLERS:
          fetchBestSellers();
          break;

        case dataSource.RELATED_PRODUCTS:
          fetchRelatedProducts();
          break;

        case dataSource.POPULAR_THIS_MONTH:
          fetchPopularThisMonth();
          break;
        case dataSource.HAGGLE_REWARDS:
          fetchHaggleRewards();
          break;

        case dataSource.RECOMMENDED_SEARCHES:
          fetchRecommendedSearches();
          break;

        case dataSource.MIX_MATCH_VOLUME_DISCOUNT:
          fetchMixMatchVolumeProducts();
          break;
      }
    }
  }
  const router = useRouter()
  const [isBrowserButtonClicked, setIsBrowserButtonClicked] = useState(false);
  useEffect(() => {
    if (isBrowserButtonClicked) {
      fetchCarouselItems()
      setIsBrowserButtonClicked(false);
    }
  }, [router.query]);
  useEffect(() => {
    const handlePopstate = (event: any) => {
      if (event.state) {
        setIsBrowserButtonClicked(true);
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [router]);

  useEffect(() => {
    fetchCarouselItems()
  }, [
    Object?.keys(customerStatus)?.length,
    defaultPostZip,
    shippingAddresses,
    showNonActiveProduct,
    isVendor,
    vendorID,
    isGuestUser,
    mainCategory,
    subCategory
  ]);

  useEffect(() => {
    if (productData?.length > 0 && !guestUser) {
      const observable = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            addGoogleAnalytics("view_item_list");
            observable.unobserve(carouselRef.current);
          }
        },
        { threshold: 0.2 }
      );
      if (carouselRef.current) {
        observable.observe(carouselRef.current);
      }
      return () => {
        if (carouselRef.current) {
          observable.unobserve(carouselRef.current);
        }
      };
    }
  }, [productData]);

  return (
    <>
      {isGuestUser && (AllowedUsers === "Guest" || AllowedUsers === "Both") && (
        <>
          {isLoading ? (
            (dataSrc === dataSource.RECENTLY_VIEWED ||
              dataSrc === dataSource.VIEWED_ITEMS) &&
            productData ? (
              productData?.length ? (
                <>
                  <h1 className="font-bold-h5">
                    <span>{title}</span>
                    {viewAll && (
                      <Link href={btnUrl}>
                        <a className={styles.viewAllText}>
                          <Button theme="link">{getText()?.General?.viewAll}</Button>
                        </a>
                      </Link>
                    )}
                  </h1>
                  <div className="py-30" style={{ textAlign: "center" }}>
                    <DataLoader />
                  </div>
                </>
              ) : null
            ) : (
              <>
                <div
                  className="py-30 container"
                  style={{ textAlign: "center" }}
                >
                  <Skeleton />
                </div>
              </>
            )
          ) : (
            <Row style={{ backgroundColor }}>
              {data &&
                (productData || feedBackData) &&
                (productData?.length > 0 || feedBackData?.length > 0) && (
                  <div
                    className={classnames(
                      "container pt-0",
                      styles.carouselContainer,
                      type === "Feedbacks" && styles.feedbackContainer,
                      carouselClass === "searchPageCarousel"
                        ? styles.searchPageCarousel
                        : carouselClass === "recentlyViewed"
                        ? styles.recentlyViewed
                        : carouselClass === "recentlyViewedPDP"
                        ? styles.recentlyViewedPDP
                        : carouselClass === "customer reviews"
                        ? styles.customerReviews
                        : carouselClass === "hubArticles"
                        ? styles.hubArticles
                        : carouselClass === "store"
                        ? styles.storeCarousel
                        : carouselClass === "mixMatchCarousel"
                        ? styles.mixMatchCarousel
                        : ""
                    )}
                  >
                    <div className={styles.titleContainer}>
                      <h1 className={styles.carouselTitle}>
                        <span>{title}</span>
                        {viewAll && (
                          <Link href={btnUrl}>
                            <a className={styles.viewAllText}>
                              <Button theme="link">
                                {getText()?.General?.viewAll}
                              </Button>
                            </a>
                          </Link>
                        )}
                      </h1>
                    </div>
                    {subTitle && (
                      <p
                        className={`mb-0 ${styles.subTitle}`}
                        dangerouslySetInnerHTML={{
                          __html: subTitle,
                        }}
                      />
                    )}
                    {data && productData && (
                      <Carousel
                        {...settings}
                        nextArrow={<NextArrow />}
                        prevArrow={<PrevArrow />}
                      >
                        {type === "Articles"
                          ? data?.map((_, index) => (
                              <ArticleCard
                                key={index}
                                readMoreUrl=""
                                title={getText().Home.articlesTitle}
                                categories={[
                                  "Expert advice",
                                  "K12 / Schools",
                                  "Retail",
                                  "",
                                ]}
                              />
                            ))
                          : type === "Feedbacks"
                          ? feedBackData?.map((item) => (
                              <FeedbackCard
                                key={item?.id}
                                title={item?.title}
                                description={item?.review}
                                userImage={item?.author_image}
                                userName={item?.author_name}
                                userPosition={item?.author_position}
                              />
                            ))
                          : productData?.map((item: any, index: any) => (
                              <ProductCard
                                key={index}
                                titleOfList={title}
                                id={index}
                                isCarousel={true}
                                isMixAndMatchCarousel={
                                  carouselClass === "mixMatchCarousel"
                                }
                                product={item}
                              />
                            ))}
                      </Carousel>
                    )}
                  </div>
                )}
            </Row>
          )}
        </>
      )}
      {!isGuestUser &&
        (AllowedUsers === "Registered" || AllowedUsers === "Both") && (
          <div ref={carouselRef}>
            {isLoading ? (
              dataSrc === dataSource.RECENTLY_VIEWED ||
              dataSrc === dataSource.VIEWED_ITEMS ? (
                productData?.length ? (
                  <>
                    <h1 className="font-bold-h5">
                      <span>{title}</span>
                      {viewAll && (
                        <Link href={btnUrl}>
                          <a className={styles.viewAllText}>
                            <Button theme="link" onClick={onclick}>{getText()?.General?.viewAll}</Button>
                          </a>
                        </Link>
                      )}
                    </h1>
                    <div className="py-30" style={{ textAlign: "center" }}>
                      <DataLoader />
                    </div>
                  </>
                ) : null
              ) : (
                <>
                  <CarouselSkeleton type="mixMatchCarousel" />
                </>
              )
            ) : (
              <Row style={{ backgroundColor }}>
                {data &&
                  (productData || feedBackData) &&
                  (productData?.length > 0 || feedBackData?.length > 0) && (
                    <div
                      className={classnames(
                        "container pt-0",
                        styles.carouselContainer,
                        type === "Feedbacks" && styles.feedbackContainer,
                        dataSrc === dataSource.POPULAR_THIS_MONTH &&
                          styles.popularThisMonth,
                        dataSrc === dataSource.FEATURED_PRODUCT &&
                          styles.featuredProduct,
                        dataSrc === dataSource.RECENTLY_VIEWED &&
                          styles.recentlyViewedHome,
                        carouselClass === "searchPageCarousel"
                          ? styles.searchPageCarousel
                          : carouselClass === "recentlyViewed"
                          ? styles.recentlyViewed
                          : carouselClass === "recentlyViewedPDP"
                          ? styles.recentlyViewedPDP
                          : carouselClass === "customer reviews"
                          ? styles.customerReviews
                          : carouselClass === "hubArticles"
                          ? styles.hubArticles
                          : carouselClass === "store"
                          ? styles.storeCarousel
                          : carouselClass === "mixMatchCarousel"
                          ? styles.mixMatchCarousel
                          : ""
                      )}
                    >
                      <div className={styles.titleContainer}>
                        <h1 className={styles.carouselTitle}>
                          {title}
                          {viewAll && (
                            <Link href={btnUrl}>
                              <a
                                className={`${
                                  dataSrc == dataSource.HAGGLE_REWARDS
                                    ? styles.haggleView
                                    : ""
                                } ${styles.viewAllText}`}
                                onClick={onClickHandler}
                              >
                                <Button theme="link">
                                  {getText()?.General?.viewAll}
                                </Button>
                              </a>
                            </Link>
                          )}
                        </h1>
                      </div>
                      {subTitle && (
                        <p
                          className={`mb-0 ${styles.subTitle}`}
                          dangerouslySetInnerHTML={{
                            __html: subTitle,
                          }}
                        />
                      )}
                      {data && productData && (
                        <Carousel
                          {...settings}
                          nextArrow={<NextArrow />}
                          prevArrow={<PrevArrow />}
                        >
                          {type === "Articles"
                            ? data?.map((item, index) => (
                                <ArticleCard
                                  key={index}
                                  readMoreUrl=""
                                  title={getText().Home.articlesTitle}
                                  categories={[
                                    "Expert advice",
                                    "K12 / Schools",
                                    "Retail",
                                    "",
                                  ]}
                                />
                              ))
                            : type === "Feedbacks"
                            ? feedBackData?.map((item) => (
                                <FeedbackCard
                                  key={item?.id}
                                  title={item?.title}
                                  description={item?.review}
                                  userImage={item?.author_image}
                                  userName={item?.author_name}
                                  userPosition={item?.author_position}
                                />
                              ))
                            : productData?.map((item: any, index: any) => (
                                <ProductCard
                                  id={index}
                                  key={index}
                                  isMixAndMatchCarousel={
                                    carouselClass === "mixMatchCarousel"
                                  }
                                  isCarousel={true}
                                  productCardClass={productCardClass}
                                  product={item}
                                />
                              ))}
                        </Carousel>
                      )}
                    </div>
                  )}
              </Row>
            )}
          </div>
        )}
    </>
  );
};

export default CustomCarousel;
