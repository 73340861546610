import Button from "@components/shared/Button";
import styles from "./styles.module.scss";
import classnames from "classnames";
import Link from "next/link";
import { Col, Divider, Row, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import DataLoader from "~/src/components/shared/DataLoader";
import { AppContext } from "~/src/libs/context";
import { useRouter } from "next/router";
import { getText } from "@src/libs/resources";
import Icon from "~/src/components/shared/Icon";
import AllOrders from "../../MyOrders";

const MyOrder = () => {
  const router = useRouter();
  const { setIsViewDetails, setOrderID, setContent, setCurrent } =
    useContext<any>(AppContext);
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getAllOrders = async () => {
    setIsLoading(true);
    let response;
    try {
      response = await axios.get(`/api/ERP/orders/all`, {
        headers: { sessionId: getCookie("sessionid") },
      });
      const tableData: any = [];
      response?.data?.results?.forEach((item: any, index: any) =>
        tableData?.push({
          key: index,
          orderId: item?.order_number,
          placedBy: item?.shipping_address?.contact_name,
          orderDate: item?.date_placed?.slice(0, 10),
          status: item?.customer_order_status,
        })
      );
      setDataSource(tableData?.slice(0, 4));
    } catch (error: any) {}
    setIsLoading(false);
  };
  useEffect(() => {
    getAllOrders();
  }, []);
  return (
    <>
      <div className={styles.myOrders}>
        <div className={classnames(styles.titleSection, " p-12")}>
          <span className="text-oxfordBlue font-bold-h5">
            {getText()?.Account?.myOrdersTitle}
          </span>
          <Link href={`/account/myOrders`}>
            <a
              onClick={() => {
                setIsViewDetails(false);

                setContent(<AllOrders />);
                setCurrent("myOrders");
              }}
            >
              <Button theme={"link"} className="font-bold-sm p-0">
                {getText()?.Account?.viewAllButton}
              </Button>
            </a>
          </Link>
        </div>
        {!isLoading ? (
          <Table
            scroll={{ x: 400 }}
            className={`${styles.tableWrapper} mx-12 pb-12`}
            dataSource={dataSource}
            pagination={false}
          >
            <Table.Column
              title="Order Id"
              dataIndex="orderId"
              key="orderId"
              className={`${styles.orderIdStyle} font-regular-sm`}
            />
            <Table.Column
              title="Placed by"
              dataIndex="placedBy"
              key="placedBy"
            />
            <Table.Column
              title="Order Date"
              dataIndex="orderDate"
              key="orderDate"
              className={styles.orderData}
            />
            <Table.Column title="Status" dataIndex="status" key="status" />
            <Table.Column
              key="link"
              dataIndex="link"
              render={(_: any, record: any) => (
                <Button
                  onClick={() => {
                    setIsViewDetails(true);
                    setOrderID(record?.orderId);
                    setContent(<AllOrders OrderNumber={record?.orderId} />);
                    setCurrent("myOrders");
                  }}
                  theme={"primary"}
                >
                  {getText()?.Account?.viewButton}
                </Button>
              )}
            />
          </Table>
        ) : (
          <Row justify="center" className="py-20">
            <DataLoader />
          </Row>
        )}
      </div>
      <div className={styles.myOrdersMobile}>
        <div className={classnames(styles.titleSection, " p-12")}>
          <span className="text-oxfordBlue font-bold-h5">
            {getText()?.Account?.myOrdersTitle}
          </span>
          <Link href={`/account/myOrders`}>
            <a
                onClick={() => {
                  setIsViewDetails(false);

                  setContent(<AllOrders />);
                  setCurrent("myOrders");
                }}
              >
              <Button
                theme={"link"}
                className={`font-bold-sm p-0 ${styles.viewAllButton}`}
              >
                {getText()?.Account?.viewAllButton}
              </Button>
            </a>
          </Link>
        </div>
        {!isLoading ? (
          dataSource?.length > 0 ? (
            <>
              {dataSource?.map((item: any, index: number) => (
                <>
                  <Row
                    key={index}
                    className={styles.myOrdersRow}
                    gutter={[70, 16]}
                  >
                    <Col
                      className={`${styles.trimText} font-bold-sm`}
                      span={12}
                      xs={12}
                      sm={8}
                    >
                      <p className="mb-2 font-semibold-xxs">
                        {getText()?.Account?.orderId}
                      </p>
                      <span className="text-oxfordBlue">{item?.orderId}</span>
                    </Col>
                    <Col
                      className={`${styles.trimText} font-medium-sm text-oxfordBlue`}
                      span={12}
                      xs={12}
                      sm={8}
                    >
                      <p className="mb-2 font-semibold-xxs">
                        {getText()?.Account?.placedBy}
                      </p>
                      <span className="text-oxfordBlue">{item?.placedBy}</span>
                    </Col>
                    <Col
                      className={`${styles.trimText} font-medium-sm text-oxfordBlue`}
                      span={12}
                      xs={12}
                      sm={8}
                    >
                      <p className="mb-2 font-semibold-xxs">
                        {getText()?.Account?.OrderDate}
                      </p>
                      <span className="text-oxfordBlue">{item?.orderDate}</span>
                    </Col>
                    <Col
                      className={`${styles.trimText} font-semibold-sm text-oxfordBlue`}
                      span={12}
                      xs={12}
                      sm={8}
                    >
                      <p className="mb-2 font-semibold-xxs">
                        {getText()?.Account?.status}
                      </p>
                      <span className="text-oxfordBlue">{item?.status}</span>
                    </Col>
                    <Col span={12}>
                      <Button
                        onClick={() => {
                          setIsViewDetails(true);
                          setOrderID(item?.orderId);
                          router.push({
                            pathname: `/account/myOrders`,
                            query: { orderID: item?.orderId },
                          });
                        }}
                        className="font-semibold-xs px-17"
                        theme={"primary"}
                      >
                        {getText()?.Account?.viewButton}
                      </Button>
                    </Col>
                  </Row>
                  <Divider type="horizontal" className="my-8" />
                </>
              ))}
            </>
          ) : (
            <div
              className={`text-center text-neutralGray py-20 ${styles.noDataWrapper}`}
            >
              <Icon name="noData" width={64} height={40} />
              <span className="mt-4">{getText()?.Account?.noData}</span>
            </div>
          )
        ) : (
          <Row justify="center" className="py-20">
            <DataLoader />
          </Row>
        )}
      </div>
    </>
  );
};
export default MyOrder;
