import {
  Col,
  Drawer,
  Radio,
  Row,
  Space,
  notification,
  Modal,
  Tooltip,
} from "antd";
import { useState, useContext, useEffect } from "react";
import Button from "~/src/components/shared/Button";
import Icon from "~/src/components/shared/Icon";
import styles from "./styles.module.scss";
import type { RadioChangeEvent } from "antd";
import Tags from "@components/shared/Tag";
import AddAddressForm from "~/src/components/shared/AddressDrawer/AddAddressForm";
import { getCookie } from "src/server/utils/commonUtils";
import DataLoader from "~/src/components/shared/DataLoader";
import { AppContext } from "@src/libs/context";
import { getText } from "~/src/libs/resources";
import { normalizePhoneNumber } from "~/src/libs/utils/phoneValidator";
import useClient from "~/src/server/utils/client";

const MyAddresses = () => {
  const {
    shippingAddresses,
    getShippingAddresses,
    customerStatus,
    getCustomerStatus,
    loadingShippingAddresses,
    setDefaultPostZip,
  } = useContext<any>(AppContext);
  const client = useClient({});
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [isDrawerOpend, setIsDrawerOpend] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manageAddressPermissions, setManageAddressPermissions] =
    useState<boolean>(true);

  const [defaultAddressId, setDefaultAddressId] = useState(
    shippingAddresses?.find((address: any) => address?.is_default)?.id || -1
  );
  const [errorMessage, setErrorMessage] = useState<any>();

  useEffect(() => {
    if (shippingAddresses?.length > 0) {
      const defaultAddressId = shippingAddresses?.find(
        (address: any) => address?.is_default
      )?.id;
      setDefaultAddressId(defaultAddressId);
    }
  }, [shippingAddresses]);

  useEffect(() => {
    if (customerStatus?.permissions?.includes("MANAGE_SHIPPING_ADDRESSES")) {
      setManageAddressPermissions(true);
    } else setManageAddressPermissions(false);
  }, [customerStatus]);

  const onChange = (addressId: number) => {
    setDefaultAddressId(addressId);
    onAddressAsDefaultHandler(addressId);
  };

  const companyInfo = {
    company_name: shippingAddresses[0]?.company_name || "company_name",
  };

  const onAddressAsDefaultHandler = async (addressId: number) => {
    setIsLoading(true);
    try {
      await client.basic.patch(
        `/api/ERP/customer/shippingAddress/${addressId}`,
        {
          headers: { sessionId: getCookie("sessionid") },
          data: { is_default: true },
        }
      );
      notification.success({
        message: `Your address has been successfully updated!`,
        duration: 2,
      });
      getShippingAddresses();
    } catch (e:any) {
      if (e?.response?.status === 400) {
        notification.error({
          message:
            "Please update your address and ensure all required fields are filled.",
          duration: 2,
        });
      }
      else{
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 2,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateAddress = async (values: any) => {
    setIsLoading(true);
    if (editAddress) {
      try {
        await client.basic.put(
          `/api/ERP/customer/shippingAddress/${shippingAddresses[addressToEdit]?.id}`,
          {
            headers: { sessionId: getCookie("sessionid") },
            data: { ...values, ...companyInfo },
          }
        );
        closeDrawer();
        notification.success({
          message: `Your address has been successfully updated!`,
          duration: 4,
        });
      } catch (error: any) {
        setIsLoading(false);
        const fieldsToValidate = [
          "state",
          "city",
          "country",
          "post_zip",
          "address",
        ];
        const keys = Object.keys(error?.response?.data);
        if (fieldsToValidate?.some((i) => keys?.includes(i)))
          setErrorMessage(error?.response);
        else
          notification.error({
            message: `Unable to update the selected address, please try again!`,
            duration: 4,
          });
      }
    } else {
      try {
        await client.basic.post("/api/ERP/customer/shippingAddress", {
          headers: { sessionId: getCookie("sessionid") },
          data: { ...values, ...companyInfo },
        });
        closeDrawer();
        window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        notification.success({
          message: `A new address has been successfully created!`,
          duration: 4,
        });
        if (!customerStatus?.has_addresses) {
          getCustomerStatus();
          setDefaultPostZip("");
        }
      } catch (error: any) {
        setIsLoading(false);
        const fieldsToValidate = [
          "state",
          "city",
          "country",
          "post_zip",
          "address",
        ];
        const keys = Object.keys(error?.response?.data);
        if (fieldsToValidate?.some((i) => keys?.includes(i)))
          setErrorMessage(error?.response);
        else
          notification.error({
            message: `Unable to create a new address, please try again!`,
            duration: 4,
          });
      }
    }
    setIsLoading(false);
    getShippingAddresses();
  };

  const deleteAddressHandler = async (index: number) => {
    setIsLoading(true);
    try {
      await client.basic.delete(
        `/api/ERP/customer/shippingAddress/${shippingAddresses[index]?.id}`,
        {
          headers: { sessionId: getCookie("sessionid") },
        }
      );
      notification.success({
        message: `Your address has been successfully deleted!`,
        duration: 4,
      });
      if (shippingAddresses?.length > 1) getShippingAddresses();
      else if (shippingAddresses?.length === 1) {
        await getCustomerStatus();
        getShippingAddresses();
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      notification.error({
        message: `Unable to delete the selected address, please try again!`,
        duration: 4,
      });
    }
  };
  const editAddressHandler = async (index: number) => {
    setAddressToEdit(index);
    setIsDrawerOpend(false);
    setEditAddress(true);
    document.body.style.overflow = "hidden";
  };
  useEffect(() => {
    if (editAddress && !isDrawerOpend) {
      setIsDrawerOpend(true);
      setIsDrawerVisible(true);
    }
  }, [editAddress]);
  const { confirm } = Modal;
  const showDeleteConfirm = (index: number) => {
    document.body.style.overflow = "hidden";
    confirm({
      title: "Are you sure to delete this address?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteAddressHandler(index);
        document.body.style.overflow = "auto";
      },
      onCancel() {
        document.body.style.overflow = "auto";
      },
    });
  };
  const closeDrawer = () => {
    if (editAddress) setEditAddress(false);
    hideDrawer();
    setIsDrawerOpend(false);
    document.body.style.overflow = "auto";
  };
  const hideDrawer = () => {
    const items: any = document.getElementsByClassName("pac-container");
    for (let i = 0; i < items.length; i++) {
      document.body?.append(items[i]);
    }
    setIsDrawerVisible(false);
    document.body.style.overflow = "auto";
  };
  const addNewAddressHandler = () => {
    if (editAddress) setEditAddress(false);
    setIsDrawerOpend(true);
    setIsDrawerVisible(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <div className={styles.myAddresses}>
      <div className={styles.myAddressesTitle}>
        <div className={styles.title}>
          {getText()?.Account?.myAddressesTitle}
          {isLoading || loadingShippingAddresses ? (
            <DataLoader className="py-5" />
          ) : null}
        </div>
      </div>
      <div className={styles.addressWrapper}>
        <Radio.Group
          className={styles.buttonGroup}
          onChange={(e: RadioChangeEvent) => onChange(e.target.value)}
          value={defaultAddressId}
        >
          <Row gutter={[16, 16]}>
            {shippingAddresses?.map((item: any, index: number) => (
              <Col
                key={item?.id}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 6 }}
              >
                <div className={styles.itemBox}>
                  <div className={styles.titleContainer}>
                    <h3 className={styles.trimText}>{item?.name}</h3>
                    {defaultAddressId == item?.id ? (
                      <Radio
                        value={item?.id}
                        disabled={!manageAddressPermissions}
                      ></Radio>
                    ) : (
                      <Tooltip title="Make default" placement="bottom">
                        <Radio
                          value={item?.id}
                          disabled={!manageAddressPermissions}
                        ></Radio>
                      </Tooltip>
                    )}
                  </div>
                  <div className={`${styles.trimText} pb-4`}>
                    <h3>{getText()?.Account?.addressTitle}</h3>
                    <span>{item?.address}</span>
                  </div>
                  <div className="pb-6">
                    <h3>{getText()?.Account?.phoneTitle}</h3>
                    <span>{normalizePhoneNumber(item?.phone_number)}</span>
                  </div>
                  {manageAddressPermissions && (
                    <div className={styles.lastRow}>
                      <div className={styles.icons}>
                        <Tooltip title="Edit" placement="bottom">
                          <button className="p-0">
                            <Icon
                              name="edit"
                              width={15}
                              height={15}
                              onClick={() => editAddressHandler(index)}
                            />
                          </button>
                        </Tooltip>
                        {defaultAddressId !== item?.id ?
                          <Tooltip title='Delete' placement="bottom">
                            <button className="p-0">
                              <Icon
                                name="delete"
                                width={15}
                                height={15}
                                onClick={() => showDeleteConfirm(index)}
                              />
                            </button>
                          </Tooltip>
                          :
                          null
                        }
                      </div>
                      {defaultAddressId == item?.id ? (
                        <Tags theme="light" text="Default" size="sm" />
                      ) : (
                        <a
                          onClick={() => onChange(item?.id)}
                          className={`${styles.makeDefault}`}
                        >
                          <h6 className="font-semibold-xs">
                            {getText()?.Account?.makeDefaultButton}
                          </h6>
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            ))}
            {manageAddressPermissions && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 6 }}
              >
                <Button
                  className={styles.addButton}
                  theme="secondary"
                  disabled={!customerStatus?.profile_completed}
                  onClick={() => addNewAddressHandler()}
                >
                  <div className={styles.icons}>
                    <Icon name="add" width={40} height={40} color="#ffffff" />
                  </div>
                  {getText()?.Account?.addAddressButton}
                </Button>
              </Col>
            )}
          </Row>
        </Radio.Group>
      </div>

      {isDrawerOpend && (
        <Drawer
          title=" "
          placement="right"
          closable={false}
          width="100%"
          headerStyle={{ padding: "16px 6px 0 0" }}
          style={{
            maxWidth: "550px",
            marginLeft: "auto",
          }}
          onClose={() => (editAddress ? closeDrawer() : hideDrawer())}
          open={isDrawerVisible}
          extra={
            <Space>
              <Button
                className={styles.closeButton}
                onClick={() => {
                  closeDrawer();
                }}
                hasIcon={true}
                icon={
                  <Icon
                    name="cross"
                    width={20}
                    height={20}
                    color="#000"
                    dir="top"
                  />
                }
              />
            </Space>
          }
        >
          <div className={styles.addressWrapper2}>
            <AddAddressForm
              updateAddress={updateAddress}
              initialAddress={shippingAddresses[addressToEdit]}
              editAddress={editAddress}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </Drawer>
      )}
    </div>
  );
};
export default MyAddresses;
