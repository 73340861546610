import React, { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import PromoQuestionBanner from "@components/shared/promotionQuestionBanner";
import Link from "next/link";
import NoResult from "../NoResult";
import SearchBox from "../SearchBox";
import Icon from "@components/shared/Icon";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { AppContext } from "~/src/libs/context";
import DataLoader from "~/src/components/shared/DataLoader";
import { getText } from "~/src/libs/resources";
import styles from "../styles.module.scss";
import { Image, notification } from "antd";
import { useDispatch } from "react-redux";
import { setProductListInfo } from "~/src/libs/utils/products";

const SearchResult: React.FC<any> = ({
  searchResults,
  closeMenu,
  setData,
  isSearchPage,
  isPromoQ,
  getItemId,
  isStorePage,
  isTrending,
  isHaggleSearch
}) => {
  const {
    guestUser,
    dataLoading,
    recentSearch,
    setRecentSearch,
    setOpenMenuSearch,
    setInputValue,
  } = useContext<any>(AppContext);
  const dispatch = useDispatch();
  const placeholderImage =
    "https://cdn.bfldr.com/SK3DIHJL/at/rrxr67wrr7qn946t8f9vggbg/box-empty-white.png?auto=webp&format=png";
  const router = useRouter();
  const [results, setResults] = useState(searchResults);
  const [isTrendingState, setIsTrendingState] = useState(isTrending);
  const [searchItemsThresholdHeight, setSearchItemsThresholdHeight] =
    useState(370);

  useEffect(() => {
    setIsTrendingState(isTrending);
  }, [isTrending]);
  useEffect(() => {
    setResults(searchResults);
  }, [searchResults]);
  const closeSearchMenu = () => {
    closeMenu();
    setOpenMenuSearch(false);
  };
  useEffect(() => {
    setResults(results);
  }, []);

  useEffect(() => {
    if (recentSearch?.length > 0 && isTrendingState) {
      if (isTrendingState) {
        setSearchItemsThresholdHeight(
          423 + recentSearch?.slice(0, 5)?.length * 38
        );
      }
    } else {
      setSearchItemsThresholdHeight(370);
    }
  }, [recentSearch, isTrendingState]);

  const fetchSearchedItem = async () => {
    let data;
    try {
      data = await axios.get("/api/ERP/search/recentSearch", {
        headers: { sessionId: getCookie("sessionid") },
      });
      setRecentSearch(data?.data?.history);
    } catch (error: any) {}
  };

  const removeRecentSearchKeyword = async (keyword: string) => {
    try {
      await axios
        .delete(`/api/ERP/search/delete`, {
          headers: { sessionId: getCookie("sessionid") },
          data: { keyword: keyword },
        })
        .then(() => {
          fetchSearchedItem();
        });
    } catch (error) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (!guestUser && !isHaggleSearch && !getItemId) {
      fetchSearchedItem();
    }
  }, [guestUser]);

  const recentSearchHandler = (query: string) => {
    closeMenu();
    setInputValue(query);
    router.push(`/search/${encodeURIComponent(query as string)}`);
  };

  const removeRecentSearchHandler = (e: any, item: string) => {
    e.stopPropagation();
    removeRecentSearchKeyword(item);
  };

  const isTrendingHandler = (trending: boolean) => {
    setIsTrendingState(trending);
  };
  return (
    <>
      <div
        className={`bg-white ${styles.searchResultWrapper} ${
          results?.length > 0 ? "" : styles.noResultWrapper
        } ${getItemId ? styles.compareSearchResult : ""} ${
          isStorePage ? styles.storeResult : ""
        } mt-6 ${isSearchPage ? styles.searchPageResultWrapper : ""}`}
      >
        {!isSearchPage && (
          <SearchBox
            view="mobile"
            closeMenu={closeMenu}
            data={results}
            setData={setData}
            isSearchPage={isSearchPage}
            isTrendingHandler={isTrendingHandler}
          />
        )}
        {(results?.length > 0 && !dataLoading) ? (
          <>
            <div className={styles.menu}>
              <div className={styles.menuHeader}>
                {getItemId ? (
                  <div className={`${styles.recentSearchHeader} px-10`}>
                    {isTrendingState &&
                      recentSearch?.length > 0 &&
                      !guestUser && (
                        <>
                          <h2
                            className={`font-bold-h5 text-oxfordBlue ${styles.mobileCompareSearchHistory}`}
                          >
                            {getText()?.Search?.recentSearch}
                          </h2>

                          <button onClick={closeSearchMenu} className={` p-0`}>
                            <Icon name="cross" />
                          </button>
                        </>
                      )}
                  </div>
                ) : (
                  <>
                    {isTrendingState &&
                      recentSearch?.length > 0 &&
                      !guestUser && (
                        <h2
                          className={`font-bold-h5 text-oxfordBlue ${
                            !getItemId ? "px-10" : ""
                          } `}
                        >
                          {getText()?.Search?.recentSearch}
                        </h2>
                      )}
                    <button
                      onClick={closeSearchMenu}
                      className={`${styles.closeMenu} p-0`}
                    >
                      <Icon name="cross" />
                    </button>
                  </>
                )}

                {isTrendingState && recentSearch?.length > 0 && (
                  <div>
                    {recentSearch
                      ?.slice(0, 5)
                      ?.map((item: any, index: number) => (
                        <div
                          key={index}
                          onClick={() => recentSearchHandler(item)}
                          className={`${styles.recentSearchText} ${
                            getItemId ? styles.compareRecentSearchText : ""
                          } py-3 px-10`}
                        >
                          <h4 className="m-0">{item}</h4>
                          <button
                            onClick={(e) => removeRecentSearchHandler(e, item)}
                            className="p-0"
                          >
                            <Icon name="cross" height={18} color="gray" />
                          </button>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <h5
                className={`font-bold-h5 text-oxfordBlue px-10 pt-10 ${
                  getItemId ? styles.compareHistoryTitle : ""
                }`}
              >
                {getItemId
                  ? getText()?.Search?.history
                  : isTrendingState
                  ? getText()?.Search?.trendingSearch
                  : getText()?.Search?.searchResults}
              </h5>
              <div
                className={styles.searchItems}
                style={{
                  maxHeight: `calc(100vh - ${searchItemsThresholdHeight}px)`,
                }}
              >
                {results?.map((item: any, index: number) => {
                  const ProductCard = (
                    <div
                      key={item?.name}
                      style={{ display: "flex" }}
                      onClick={() =>
                        dispatch(setProductListInfo({ id: index }))
                      }
                    >
                      {item?.plp_image_url && (
                        <Image
                          className={styles.imgContainer}
                          src={item?.plp_image_url}
                          width="58px"
                          height="47px"
                          alt={item?.name}
                          fallback={placeholderImage}
                          preview={false}
                        />
                      )}
                      <div className={styles.content}>
                        <div
                          className={classnames(
                            styles.title,
                            "font-bold-xs text-primary"
                          )}
                        >
                          {item?.name}
                        </div>
                      </div>
                    </div>
                  );

                  return getItemId ? (
                    <div
                      key={index}
                      className={`${styles.menuItemWrapper} ${
                        styles.compareSearchProductsResult
                      } ${styles.menuItem} ${
                        getItemId ? styles.compareMenuItemWrapper : ""
                      } py-8 pe-4`}
                      onClick={(e) => {
                        getItemId(item?.id as string);
                        closeMenu(e);
                      }}
                    >
                      {ProductCard}
                    </div>
                  ) : (
                    <Link
                      href={`/product/${item?.slug}`}
                      className={styles.menuItem}
                      key={index}
                    >
                      <a
                        className={`${styles.menuItemWrapper} py-4 pe-4`}
                        onClick={closeMenu}
                      >
                        {ProductCard}
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className={`bg-white p-10 ${styles.PromoQuestionContainer}`}>
              <PromoQuestionBanner
                isStorePage={isStorePage}
                promoClassname={isPromoQ ? "searchPagePromoQ" : "promoQ"}
              />
            </div>
          </>
        ) : (
          <>
            {dataLoading ? (
              <DataLoader className="py-30 text-center" size="large" />
            ) : (
              <NoResult
                isSearchPage={isSearchPage}
                closeMenu={closeMenu}
                iconSize={56}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SearchResult;
