import Icon from "~/src/components/shared/Icon";
import styles from "./styles.module.scss";
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Checkbox,
  Col,
  Row,
  notification,
  Modal,
  Tooltip,
  Form,
  Skeleton,
} from "antd";
import Card from "./Card";
import Button from "@components/shared/Button";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import {
  areArraysIdentical,
  getCookie,
  haveSimilarItem,
} from "~/src/server/utils/commonUtils";
import { AppContext } from "~/src/libs/context";
import ShareDialog from "@components/shared/ShareDialog";
import { itemViewSectionProps } from "./types";
import { useRouter } from "next/router";
import { getText } from "~/src/libs/resources";
import classNames from "classnames";
import CustomInput from "@components/shared/Fields";
import requireLogin from "~/src/utilities/auth";
import useOnClickOutside from "@src/utilities/hooks/useOutsideClick";
import AddToShoppingList from "~/src/components/shared/AddToShoppingList";
import SearchForm from "../SearchForm";
import DataLoader from "~/src/components/shared/DataLoader";

const ItemViewSection = ({ item }: itemViewSectionProps) => {
  const {
    guestUser,
    customerStatus,
    setShoppingListUpdated,
    defaultPostZip,
    setShoppingListLoading,
    shoppingList,
    getCartCount,
  } = useContext<any>(AppContext);
  const [data, setData] = useState(item?.products);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [cardCheckList, setCardCheckList] = useState<any>([]);
  const [addToCartStatus, setAddToCartStatus] = useState(false);
  const [addToShoppingListStatus, setAddToShoppingListStatus] = useState(false);
  const [isSharedWishlist, setIsSharedWishlist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>(item);
  const [nameEditLoading, setNameEditLoading] = useState<boolean>(false);
  const [productData, setProductData] = useState(item?.products);
  const [productSlugs, setProductSlugs] = useState();
  const editContainerRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const [showShoppingListDialog, setShowToShoppingListDialog] = useState(false);
  const router = useRouter();
  const [form] = Form.useForm();
  const isCustomerDataCompleted =
    !guestUser &&
    (customerStatus?.profile_completed === true ||
      customerStatus?.is_vendor === true);

  useOnClickOutside(editContainerRef, () => {
    setIsEditable(false);
  });

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? cardCheckList : []);
    setCheckAll(e.target.checked);
    setCardCheckList(e.target.checked);
  };

  useEffect(() => {
    if (showDeleteDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showDeleteDialog]);

  useEffect(() => {
    const product_array = productData?.map((product: any) => {
      return product?.id;
    });
    setCardCheckList(product_array);
  }, [checkAll, searchValue]);

  useEffect(() => {
    if (checkedList?.length !== productData?.length) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
  }, [searchValue]);

  const checkboxChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    if (list?.length == cardCheckList?.length) {
      setCheckAll(true);
    } else if (list?.length >= 0) {
      setCheckAll(false);
    }
  };

  const saveItemsToCart = async () => {
    setAddToCartStatus(true);
    const items: any = [];
    const selectedProduct: any = [];
    productData?.map((product: any) => {
      checkedList?.map((itemId: any) => {
        if (itemId === product?.id) {
          selectedProduct.push(product);
          if (product?.is_available && !product?.is_prototype) {
            items.push({
              product: product?.slug,
              quantity: product?.sold_in_stack
                ? 2
                : product?.min_pallet_quantity || 1,
            });
          }
        }
      });
    });
    const outOfStockProducts = selectedProduct?.filter(
      (product: any) =>
        product?.is_available == false || product?.is_prototype == true
    );
    if (areArraysIdentical(outOfStockProducts, selectedProduct)) {
      setAddToCartStatus(false);
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    } else {
      try {
        const res = await axios
          .post(`/api/ERP/cart/setProductQuantity`, {
            headers: { sessionId: getCookie("sessionid") },
            data: {
              items,
            },
          })
          .then(async (res) => {
            setAddToCartStatus(false);
            notification.success({
              message: haveSimilarItem(outOfStockProducts, selectedProduct)
                ? "Selected items added to cart successfully, some selected items were not added because they are out of stock."
                : "Selected items added to cart successfully.",
              duration: 2,
            });
            localStorage.setItem("CartID", res?.data?.cartId);
          })
          .catch((e) => {
            setAddToCartStatus(false);
            notification.error({
              message: getText().General.Messages.SomethingWrongTryAgain,
              duration: 4,
            });
          });
      } catch (e: any) {
        setAddToCartStatus(false);
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      }
    }
    setAddToCartStatus(false);
    setCheckAll(false);
    getCartCount();
    setCheckedList([]);
  };

  const addToCartHandler = () => {
    const listOfItems: any = [];
    let allItemsValue = 0;
    const items: any = [];
    productData?.map((product: any) => {
      checkedList?.map((itemId: any) => {
        if (itemId === product?.id) {
          items.push(product);
        }
      });
    });
    items?.forEach(function (item: any, id: number) {
      allItemsValue += item?.delivered_price;
      listOfItems?.push({
        item_id: item?.id?.toString(),
        item_name: item?.name,
        coupon: "",
        currency: "USD",
        discount: 0,
        index: id,
        item_brand: item?.brand_name,
        item_category: item?.main_category_name,
        item_category2: item?.sub_category_name,
        item_list_id: "",
        item_list_name: "",
        item_variant: item?.pack_size,
        price: item?.delivered_price,
        quantity: 1,
      });
    });
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "USD",
        value: allItemsValue,
        items: listOfItems,
      },
    });
  };

  const getListDetails = async () => {
    setIsLoading(true);
    if (item?.uid !== undefined) {
      try {
        let _url = `/api/ERP/customer/wishlist/${item?.uid}`;
        if (
          defaultPostZip &&
          (!customerStatus?.profile_completed || !customerStatus?.has_addresses)
        ) {
          _url += `?post_zip=${defaultPostZip}`;
        }
        await axios
          .get(_url, {
            headers: { sessionId: getCookie("sessionid") },
          })
          .then((res) => {
            setProductData(res?.data?.products);
            setData(res?.data?.products);
            setIsLoading(false);
          })
          .catch((error) => {});
      } catch (error) {}
    }
  };

  const unwishItems = async () => {
    setIsLoading(true);
    const items: any = [];
    const itemsName: any = [];
    productData?.map((product: any) => {
      checkedList?.map((itemId: any) => {
        if (itemId === product?.id) {
          items.push({
            product: product?.slug,
          });
          itemsName.push(product?.name);
        }
      });
    });
    const data = {
      items,
      wishlist_uids: [item?.uid],
    };
    await axios
      .post("/api/ERP/customer/wishlist/unwish", {
        data,
        headers: { sessionId: getCookie("sessionid") },
      })
      .then(() => {
        getListDetails();
        setShowDeleteDialog(false);
        notification.success({
          message:
            "Selected items removed from your shopping list successfully.",
          duration: 2,
        });
        window?.dataLayer?.push({
          event: "remove_from_wishlist",
          selected_item:
            itemsName?.length > 1 ? itemsName?.join(" , ") : itemsName[0],
        });
        setCheckAll(false);
        setCheckedList([]);
      })
      .catch(() => {
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      });
  };

  const postUpdate = async (values: any) => {
    setShoppingListUpdated(false);
    setIsEditable(false);
    if (values?.name !== currentItem?.name) {
      setNameEditLoading(true);
      try {
        await axios
          .put(`/api/ERP/customer/wishlist/${item.uid}`, {
            name: values.name,
            is_default: false,
            headers: { sessionId: getCookie("sessionid") },
          })
          .then((res) => {
            setCurrentItem(res.data);
            setShoppingListUpdated(true);
            notification.success({
              message: "Name edited successfully.",
              duration: 2,
            });
            setNameEditLoading(false);
          })
          .catch(() => {
            notification.error({
              message: getText().General.Messages.SomethingWrongTryAgain,
              duration: 2,
            });
            setNameEditLoading(false);
          });
      } catch (error) {
        setNameEditLoading(false);
      }
    }
  };

  const addToShoppingListHandler = async () => {
    setShowToShoppingListDialog(true);
    setAddToShoppingListStatus(true);
    setShoppingListLoading(true);
    const items: any = [];
    productData?.map((product: any) => {
      checkedList?.map((itemId: any) => {
        if (itemId === product?.id) {
          items.push(product?.slug);
        }
      });
    });
    const arrayOfSlug: any = [];
    productData?.forEach((item: any) => {
      arrayOfSlug?.push(item?.slug);
    });
    setProductSlugs(items);
    setShoppingListLoading(false);
    setAddToShoppingListStatus(false);
  };

  const handleSearch = (query: string) => {
    setSearchValue(query);
    if (query) {
      const filteredResults = data?.filter(
        (item: any) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.brand_name.toLowerCase().includes(query.toLowerCase()) ||
          item.description_short.toLowerCase().includes(query.toLowerCase())
      );
      setProductData(filteredResults);
    } else {
      setProductData(data);
    }
  };
  useEffect(() => {
    const inShoppingList = shoppingList?.some(
      (obj: any) => item?.uid === obj?.uid
    );
    if (!inShoppingList) {
      setIsSharedWishlist(true);
    } else {
      setIsSharedWishlist(false);
    }
  }, [shoppingList]);
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "grid", placeContent: "center", height: "500px" }}
        >
          <DataLoader size="large" className="container d-block my-40" />
        </div>
      ) : (
        <>
          <div className={`${styles.itemViewSection} pb-30`}>
            <div className={styles.titleSection}>
              <div className={`${styles.trimSection} me-4`}>
                <div
                  className={`${styles.trackingID} font-bold-h2`}
                  style={{ display: "flex" }}
                  ref={editContainerRef}
                >
                  {isEditable ? (
                    <>
                      <Form
                        className={styles.editNameForm}
                        layout="inline"
                        onFinish={postUpdate}
                        form={form}
                        name="Edit ShoppingList"
                        preserve={false}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            form.submit();
                          }
                        }}
                      >
                        <Form.Item
                          name="name"
                          validateTrigger={["onChange", "onBlur"]}
                          hasFeedback
                          initialValue={currentItem.name}
                          className={styles.shoppingListName}
                          rules={[
                            {
                              required: true,
                              message: "Name is required",
                            },
                            { whitespace: true },
                            {
                              max: 30,
                              message:
                                "Maximum number of characters allowed is 30",
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (!value) {
                                  callback(rule.message as any);
                                  return;
                                }
                                const nameExists = shoppingList
                                  .filter(
                                    (item: any) => item.id !== currentItem.id
                                  )
                                  .some(
                                    (item: any) => item?.name === value.trim()
                                  );
                                if (!nameExists) {
                                  callback();
                                } else {
                                  callback(
                                    "Shopping list name should be unique."
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          <CustomInput
                            className={classNames(
                              "my-3 py-4",
                              styles.customEditInput
                            )}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Tooltip title="save">
                            <button
                              onClick={() => form.submit()}
                              aria-label="save shopping list"
                              className={`${styles.editNameBtn} mt-6`}
                            >
                              <Icon name="check" width={25} height={25} />
                            </button>
                          </Tooltip>
                        </Form.Item>
                      </Form>
                    </>
                  ) : (
                    <>
                      {nameEditLoading ? (
                        <>
                          <Skeleton.Input active />
                        </>
                      ) : (
                        <>
                          {currentItem?.name}
                          {!isSharedWishlist && (
                            <Tooltip title="edit">
                              <button
                                onClick={() => setIsEditable(true)}
                                aria-label="Edit shopping list"
                                className={`${styles.editNameBtn} mt-2`}
                              >
                                <Icon name="edit" width={30} height={30} />
                              </button>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {!isSharedWishlist && (
                <div>
                  <Tooltip title="Share" placement="bottom">
                    <Button
                      className={`p-0 ${styles.shareBtn}`}
                      onClick={() => setShowShareDialog(true)}
                      aria-label="Share shopping list"
                    >
                      <Icon name="share" width={28} height={28} />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>

            <div className={styles.settingContainer}>
              {productData?.length > 0 && (
                <div className={styles.settingSection}>
                  <Checkbox
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    value="SelectAll"
                    className={`pe-18 ${styles.selectAll}`}
                  >
                    {getText()?.Account?.selectAll}
                  </Checkbox>
                  {(checkAll || checkedList?.length > 0) &&
                    !guestUser &&
                    isCustomerDataCompleted && (
                      <>
                        <Button
                          theme="secondary"
                          className={`${styles.addBtn} px-6 py-5 h-auto `}
                          onClick={addToShoppingListHandler}
                          loading={addToShoppingListStatus}
                          aria-label="Add selected items to Shopping List"
                        >
                          {getText()?.General?.addToList}
                        </Button>
                        {(customerStatus?.email_verified ||
                          customerStatus?.is_vendor) && (
                          <Button
                            theme="secondary"
                            onClick={async () => {
                              await saveItemsToCart();
                              addToCartHandler();
                            }}
                            loading={addToCartStatus}
                            aria-label="Add selected items to Cart"
                            className={`${styles.addBtn} px-6 py-5 h-auto `}
                          >
                            {getText()?.General?.addToCart}
                          </Button>
                        )}
                        {(checkAll || checkedList?.length > 0) && (
                          <Button
                            theme="secondary"
                            className="px-6 py-5 h-auto "
                            onClick={() => {
                              setShowDeleteDialog(true);
                              setShoppingListUpdated(true);
                            }}
                            aria-label="Remove selected items from shopping list"
                          >
                            {checkAll ? (
                              <>{getText()?.General?.deleteAll}</>
                            ) : (
                              <>{getText()?.General?.delete}</>
                            )}
                          </Button>
                        )}
                      </>
                    )}
                </div>
              )}
              <div className={styles.btnGroup}>
                <SearchForm
                  onSearch={handleSearch}
                  setCheckedList={setCheckedList}
                  setCheckAll={setCheckAll}
                />
                {productData?.length > 0 && (
                  <Checkbox
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    value="SelectAll"
                    className={styles.selectAllForMobile}
                  >
                    {getText()?.Account?.selectAll}
                  </Checkbox>
                )}
              </div>
            </div>

            <Button
              theme="link"
              onClick={() => router.push("/account/shoppingList")}
              aria-label="Back to shopping list page"
              icon={<Icon name="arrow" dir="bottom" />}
              className={`my-3 ${styles.backToShoppingListPage}`}
            >
              {getText()?.Account?.back}
            </Button>
            <Checkbox.Group
              className={`${
                productData?.length > 0
                  ? "w-100"
                  : styles.emptyCheckboxContainer
              }`}
              onChange={checkboxChange}
              value={checkedList}
            >
              {productData?.length > 0 ? (
                <Row className="w-100" gutter={[6, 6]}>
                  {productData?.map((item: any, index: number) => (
                    <Col
                      span={24}
                      xl={{ span: 6 }}
                      lg={{ span: 8 }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      key={index}
                      className={`ps-4 ${styles.productWrapper}`}
                    >
                      <Card
                        item={item}
                        key={item.id}
                        uid={item.id}
                        isCustomerDataCompleted={isCustomerDataCompleted}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className={`${styles.emptyWishlist} container`}>
                  <h3>{getText()?.Account?.emptyShoppingListMessage}</h3>
                </div>
              )}
            </Checkbox.Group>
          </div>
        </>
      )}
      {showShoppingListDialog && (
        <AddToShoppingList
          setShowToShoppingListDialog={setShowToShoppingListDialog}
          isModalOpen={showShoppingListDialog}
          gaForWishList={() => addToCartHandler()}
          isAddToShoppingList={true}
          slugs={productSlugs}
          setCheckedList={setCheckedList}
          setCheckAll={setCheckAll}
        />
      )}
      <Modal
        open={showDeleteDialog}
        title="Delete Confirmation"
        onCancel={() => setShowDeleteDialog(false)}
        onOk={unwishItems}
      >
        <div className={styles.closeModal}>
          <label>{getText()?.Account?.removeItemsConfirmation}</label>
        </div>
      </Modal>
      <ShareDialog
        setIsModalOpen={setShowShareDialog}
        isModalOpen={showShareDialog}
        isProductShare={false}
        shoppingListDetails={currentItem}
      />
    </>
  );
};

export default requireLogin(ItemViewSection, "/");
