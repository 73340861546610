import { memo } from "react";
import { Button, Tooltip } from "antd";
import styles from "./style.module.scss";
import Icon from "@components/shared/Icon";
import { wishListProps } from "./types";

const WishList = ({ favClick, onClick, isWishlist }: wishListProps) => {
  return (
    <Tooltip
      title={isWishlist ? "Remove from shopping list" : "Add to shopping list"}
      placement="bottom"
    >
      <Button
        className={styles.heartBtn}
        onClick={onClick}
        aria-label={
          favClick ? "Product added to wishlist" : "Add product to wishlist"
        }
      >
        <Icon name={isWishlist ? "heart-fill" : "heart"} width={20} height={20} />
      </Button>
    </Tooltip>
  );
};

export default memo(WishList);
