import styles from "./styles.module.scss";
import { Col, Row } from "antd";
import MyOrders from "./MyOrders";
import AccountOverview from "./AccountOverview";
import ShoppingLists from "./ShoppingLists";
import CustomCarousel from "~/src/components/shared/CustomCarousel";
import { useRouter } from "next/router";
import { useEffect, useState, useContext } from "react";
import ItemViewSection from "../ShoppingList/ItemViewSection";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import SEO from "@components/shared/SEO";
import CustomButton from "~/src/components/shared/Button";
import HaggleReward from "./HaggleReward";
import ViewedItems from "../ViewedItems";

const Dashboard = () => {
  const [isItemView, setItemView] = useState(false);
  const [showOrders, setShowOrders] = useState<boolean>(true);
  const [ItemViewData, setItemViewData] = useState();
  const { customerStatus, setCurrent, setContent } =
    useContext<any>(AppContext);

  const router = useRouter();
  useEffect(() => {
    if (
      !customerStatus?.permissions?.includes("MANAGE_ORDERS") &&
      !customerStatus?.permissions?.includes("VIEW_ORDERS")
    ) {
      setShowOrders(false);
    } else setShowOrders(true);
  }, [customerStatus]);

  return (
    <>
      <SEO
        title={getText().Account?.SEO?.dashboard?.title}
        description={getText().Account?.SEO?.dashboard?.description}
      />
      {isItemView ? (
        <ItemViewSection item={ItemViewData} setItemView={setItemView} />
      ) : (
        <div className={`${styles.dashboardWrapper} `}>
          <div className={styles?.titleSection}>
            <h2 className="mt-20 mb-12 font-bold-h2">
              {getText()?.Account?.dashboardTitle}
            </h2>
            {customerStatus?.is_vendor && (
              <CustomButton
                theme="link"
                className="mt-20 mb-12 font-bold-base"
                onClick={() => {
                  router?.push(process?.env?.NEXT_PUBLIC_VENDOR_URL || "/");
                }}
                text={getText()?.Account?.visitVendorDashboard}
              />
            )}
          </div>
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 8 }}
            >
              <AccountOverview />
            </Col>
            {showOrders && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 16 }}
              >
                <MyOrders />
              </Col>
            )}

            {!customerStatus?.is_vendor && (
              <Col span={24} className={styles.joinHaggle}>
                <HaggleReward />
              </Col>
            )}
            {!(
              !customerStatus?.is_vendor && !customerStatus?.profile_completed
            ) && (
              <Col span={24}>
                <ShoppingLists
                  setItemViewData={setItemViewData}
                  setItemView={setItemView}
                />
              </Col>
            )}
            <Col span={24}>
              <CustomCarousel
                title="Recently Viewed"
                viewAll={true}
                onClickHandler={() => {
                  setContent(<ViewedItems/>);
                  setCurrent("viewedItems");
                }}
                carouselClass="recentlyViewed"
                productCardClass="recentlyViewedCard"
                dataSrc="Viewed Items"
                btnUrl="/account/viewedItems"
                carouselSettings={{
                  slidesToShow: 2.5,
                  responsive: {
                    "1440": {
                      slidesToShow: 2,
                    },
                    "1023": {
                      slidesToShow: 1.5,
                    },
                    "768": {
                      slidesToShow: 1.25,
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Dashboard;
