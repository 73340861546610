import axios from "axios";
import classNames from "classnames";
import { useRouter } from "next/router";
import { Form, notification } from "antd";
import { useContext, useEffect, useState, useRef } from "react";
import ShareDialog from "@components/shared/ShareDialog";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import CustomButton from "@components/shared/Button";
import CustomInput from "@components/shared/Fields";
import DataLoader from "~/src/components/shared/DataLoader";
import Tag from "@components/shared/Tag";
import Icon from "~/src/components/shared/Icon";
import { Tooltip } from "antd";

import {
  getCookie,
} from "~/src/server/utils/commonUtils";
import useOnClickOutside from "@src/utilities/hooks/useOutsideClick";
import styles from "./styles.module.scss";

const ShoppingListCard = ({
  item,
  index,
  openModal,
  className,
  getShoppingList,
  shoppingList,
}: any) => {
  const { guestUser, customerStatus, getCartCount } =
    useContext<any>(AppContext);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>(item);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [addToCartStatus, setAddToCartStatus] = useState(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const router = useRouter();
  const editContainerRef = useRef<HTMLDivElement>(null);
  const isCustomerDataCompleted =
    !guestUser &&
    ((customerStatus?.profile_completed && customerStatus?.email_verified) || customerStatus?.is_vendor);

  useOnClickOutside(editContainerRef, () => {
    setIsEditable(false);
  });

  useEffect(() => {
    setIsDefault(item?.is_default);
  }, [item]);

  const postUpdate = async (values: any) => {
    setIsEditable(false);
    if (values?.name !== currentItem?.name) {
      setIsLoading(true);
      try {
        await axios
          .put(`/api/ERP/customer/wishlist/${item.uid}`, {
            name: values.name,
            is_default: false,
            headers: { sessionId: getCookie("sessionid") },
          })
          .then((res) => {
            setCurrentItem(res?.data);
            getShoppingList();
            form.setFieldValue("name", res?.data?.name);

            notification.success({
              message: "Name edited successfully!",
              duration: 2,
            });
          })
          .catch((err) => {
            notification.error({
              message: getText().General.Messages.SomethingWrongTryAgain,
              duration: 2,
            });
          });
        setIsLoading(false);
      } catch (error) {}
    }
  };

  const setAsDefault = async (item: any) => {
    try {
      setIsLoading(true);
      await axios
        .put(`/api/ERP/customer/wishlist/${item.uid}`, {
          name: item.name,
          is_default: true,
          headers: { sessionId: getCookie("sessionid") },
        })
        .then((res) => {
          setIsDefault(res.data.is_default);
          notification.success({
            message: "Successfully updated as default!",
            duration: 2,
          });
          getShoppingList();
        })
        .catch(() => {});
    } catch (error) {
      notification.error({
        message: `${getText().General.Messages.SomethingWrongTryAgain}`,
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addToCartHandler = () => {
    const listOfItems: any = [];
    let allItemsValue = 0;
    item?.products?.forEach(function (item: any, id: number) {
      allItemsValue += item?.delivered_price;
      listOfItems?.push({
        item_id: item?.id?.toString(),
        item_name: item?.name,
        coupon: "",
        currency: "USD",
        discount: 0,
        index: id,
        item_brand: item?.brand_name,
        item_category: item?.main_category_name,
        item_category2: item?.sub_category_name,
        item_list_id: "",
        item_list_name: "",
        item_variant: item?.pack_size,
        price: item?.delivered_price,
        quantity: 1,
      });
    });
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "USD",
        value: allItemsValue,
        items: listOfItems,
      },
    });
  };

  const addWishListToCart = async () => {
    setAddToCartStatus(true);
    let response;
    try {
      response= await axios
        .post(
          `/api/ERP/customer/wishlist/${item?.uid}/addToCart`,
          {
            headers: { sessionId: getCookie("sessionid") },
          }
        )
        getCartCount()
        notification.success({
          message: response?.data?.detail,
          duration: 2,
        });
    } catch (error) {
      notification.error({
        message: response?.data?.detail || getText().General.Messages.SomethingWrongTryAgain,
        duration: 2,
      });

    }finally{
      setAddToCartStatus(false);
    }
  };

  const viewDetailsHandler = () => {
    router.push(`/shared/wishlist/${item.uid}`);
  };

  return (
    <div
      className={`${
        styles.shoppingListItem
      } p-8 pb-10 font-bold-h5 ${
        className == "dahboardstyle" ? styles.dahboardstyle : ""
      }`}
      key={index}
    >
      <div
        className={`${styles.shoppingListItem__header} mb-8`}
        ref={editContainerRef}
      >
        {isLoading ? (
          <DataLoader />
        ) : (
          <>
            {isEditable ? (
              <>
                <Form
                  className={styles.editNameForm}
                  onFinish={postUpdate}
                  form={form}
                  name="Edit ShoppingList"
                  initialValues={{ name: currentItem.name }}
                  preserve={false}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      form.submit();
                    }
                  }}
                >
                  <Form.Item
                    name="name"
                    validateTrigger={["onChange", "onBlur"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                      { whitespace: true },
                      {
                        max: 30,
                        message: "Maximum number of characters allowed is 30",
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (!value) {
                            callback(rule.message as any);
                            return;
                          }

                          const nameExists = shoppingList
                            .filter((item: any) => item.id !== currentItem.id)
                            .some((item: any) => item?.name === value.trim());
                          if (!nameExists) {
                            callback();
                          } else {
                            callback("Shopping list name should be unique.");
                          }
                        },
                      },
                    ]}
                  >
                    <CustomInput
                      className={classNames(
                        "my-3 py-4",
                        styles.customEditInput
                      )}
                    />
                  </Form.Item>
                </Form>
              </>
            ) : (
              <>
                {currentItem.name && (
                  <div className={styles.shoppingListDetails}>
                    <h4 className={`mb-4 font-bold-h5 ${styles.shoppingListName}`}>
                      {currentItem.name}
                    </h4>
                    {item?.products_ids?.length > 0 ? (
                      <p
                        className={`font-regular-sm mb-5`}
                      >
                        {item?.products_ids?.length}
                        {item?.products_ids?.length > 1 ? " Products" : " Product"}
                      </p>
                    ) : (
                      <p
                        className="font-regular-sm mb-5">
                        {getText()?.Account?.emptyShoppingListMessage}
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className={styles.actionsContainer}>
          <div className={styles.shoppingListItem__header__icons}>
            <Tooltip title="Share" placement="bottom">
              <button
                className="p-0"
                onClick={() => setShowShareDialog(true)}
                aria-label="Share shopping list"
              >
                <Icon name="share" width={15} height={15} />
              </button>
            </Tooltip>
            {isEditable ? (
              <button
                title="save"
                className="p-0"
                onClick={() => form.submit()}
                aria-label="Edit shopping list"
              >
                <Icon name="check" width={15} height={15} />
              </button>
            ) : (
              <Tooltip title="Edit" placement="bottom">
                <button
                  className="p-0"
                  onClick={() => setIsEditable(true)}
                  aria-label="Edit shopping list"
                >
                  <Icon name="edit" width={15} height={15} />
                </button>
              </Tooltip>
            )}
            <Tooltip title="Remove" placement="bottom">
              <button
                className="p-0"
                onClick={() => openModal(currentItem)}
                aria-label="Remove shopping list"
              >
                <Icon name="delete" width={15} height={15} />
              </button>
            </Tooltip>
          </div>
          <div className="mt-2">
            {!isDefault ? (
              <CustomButton
                theme="link"
                className="font-regular-sm "
                onClick={() => setAsDefault(item)}
              >
                {getText()?.Account?.setAsDefault}
              </CustomButton>
            ) : (
              <div className={styles.tagContainer}>
                <Tag
                  theme="light"
                  className={`font-bold-xs text-oxfordBlue mt-4 me-0`}
                  text="Default"
                  hasIcon={false}
                  size={"sm"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <CustomButton
          onClick={viewDetailsHandler}
          theme="secondary"
          className={`${styles.viewBtn} ${
            item?.products_ids?.length < 1 ? styles.disabledView : ""
          } font-bold-xs`}
          disabled={item?.products_ids?.length < 1 ? true : false}
        >
          {getText()?.Account?.viewButton}
        </CustomButton>
        {!guestUser && isCustomerDataCompleted && (
          <CustomButton
            theme="primary"
            onClick={async () => {
              addWishListToCart();
              addToCartHandler();
            }}
            className="font-bold-xs"
            disabled={item?.products_ids?.length < 1 ? true : false}
            loading={addToCartStatus}
          >
            {getText()?.Account?.addToCart}
          </CustomButton>
        )}
      </div>
      <ShareDialog
        setIsModalOpen={setShowShareDialog}
        isModalOpen={showShareDialog}
        isProductShare={false}
        shoppingListDetails={currentItem}
      />
    </div>
  );
};

export default ShoppingListCard;
